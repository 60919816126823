import Modal from 'react-modal'
import Button from '../Button'

const Alert = ({
  children,
  visible,
  onClose,
  onSubmit,
  hideButton,
  style,
  disabled,
  onlyConfirm
}) => {
  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      height: 'fit-content',
      maxHeight: 460,
      maxWidth: 600,
      margin: 'auto',
      paddingTop: '2.5rem',
      paddingLeft: '2rem'
    },
    overlay: {
      zIndex: 400,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  return (
    <Modal isOpen={visible} style={customStyles} contentLabel='Alert' onRequestClose={onClose}>
      <div className='flex flex-col'>
        <div className='text-center text-lg mb-6'>{children}</div>
        {onSubmit ? (
          <div className='flex flex-col gap-3'>
            <Button
              visible={visible}
              name={'Confirmar'}
              style={{ marginBottom: 15, zIndex: 300, fontSize: '18px' }}
              onClick={() => {
                onSubmit()
                if (!onlyConfirm) onClose()
              }}
              disabled={disabled}
            />
            <Button
              visible={visible}
              name={'Cancelar'}
              style={{
                marginBottom: 15,
                zIndex: 300,
                backgroundColor: 'gray',
                fontSize: '18px'
              }}
              onClick={onClose}
            />
          </div>
        ) : (
          <div className='w-full text-center' hidden={hideButton}>
            <Button
              visible={visible}
              name={'Aceptar'}
              style={{ marginBottom: 15, zIndex: 300 }}
              onClick={onClose}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Alert
