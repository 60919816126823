import GroupImage from '../GroupImage'
import LoadingIndicator from '../../common/LoadingIndicator'
import { ADMIN_PROFILE } from '../../config/Chat'
import Button from '../../common/Button'
import { Tooltip } from '@mui/material'
import { color, label } from '../Messages/Tags'
import { useState } from 'react'

export default ({ setSelectedChannel, selectedChannel, channels }) => {
  const [offset, setOffset] = useState(1)
  const user = ADMIN_PROFILE.user_id

  const onSelect = channel => {
    if (selectedChannel?._id == channel._id) return
    setSelectedChannel(null)
    setTimeout(() => {
      setSelectedChannel(channel)
    }, 100)
  }

  return (
    <div
      className={`flex flex-col gap-2 md:w-1/2 overflow-auto h-[93%] md:h-[90%] ${
        selectedChannel ? 'pb-20' : 'pb-5'
      }`}
    >
      {!channels ? (
        <div className='flex h-full justify-center items-center'>
          <LoadingIndicator />
        </div>
      ) : channels?.length == 0 ? (
        <div className='flex flex-col h-full justify-center items-center px-5'>
          <img src={require('../../assets/img/empty-chat.png')} alt='vacio' className='w-full' />
          <p className='text-lg text-gray text-center'>No tenés mensajes en este momento</p>
        </div>
      ) : (
        channels
          ?.slice(0, offset * 30)
          .map(channel => (
            <ChannelPreview
              userId={user}
              key={channel._id}
              channel={channel}
              onSelect={() => onSelect(channel)}
              selected={selectedChannel?._id == channel._id}
            />
          ))
      )}
      {(channels?.length >= (offset + 1) * 30 || channels?.length > offset * 30) && (
        <Button
          name={'Cargar más'}
          style={{
            width: 'fit-content',
            alignSelf: 'center',
            height: 40,
            borderRadius: 10,
            fontSize: 18
          }}
          onClick={() => {
            setOffset(offset + 1)
          }}
        />
      )}
    </div>
  )
}

const ChannelPreview = ({ userId, channel, onSelect, selected }) => {
  const unreadMessages = userId
    ? channel.messages
        .filter(message => message.user_id != userId)
        .filter(message => !message.read_by?.includes(userId)).length
    : 0
  const directMessageName = channel.users.map(user => user.name).join(' y ')

  return (
    <div
      className={`flex flex-row items-center justify-between cursor-pointer hover:bg-light_gray p-3 rounded-xl mx-3 ${
        selected && 'bg-light_gray'
      }`}
      onClick={onSelect}
    >
      <div className='flex flex-row gap-4 items-center w-[93%]'>
        <GroupImage images={channel.users.map(user => user.picture)} />
        <div className='flex flex-col max-h-[3rem] w-[80%]'>
          <p className='text-base overflow-hidden block whitespace-nowrap text-ellipsis'>
            {channel.name ?? directMessageName}
          </p>
          <p className='text-sm text-gray pr-3 overflow-hidden block whitespace-nowrap text-ellipsis'>
            {lastMessage(channel, userId)}
          </p>
        </div>
        <div className='flex items-center gap-2'>
          {channel?.tags?.map(tag => (
            <Tooltip title={label(tag)}>
              <div
                style={{ backgroundColor: color(tag) }}
                className='w-3 h-3 rounded-full border-black border-2'
              />
            </Tooltip>
          ))}
        </div>
      </div>
      <div className='w-[7%] ml-2'>
        {unreadMessages > 0 ? (
          <div className='flex text-white text-xs items-center justify-center bg-primary rounded-[50%] w-6 h-6 p-3'>
            {unreadMessages}
          </div>
        ) : (
          <div className='w-6 h-6' />
        )}
      </div>
    </div>
  )
}

const lastMessage = (channel, userId) => {
  const lastMessage = channel.messages[channel.messages.length - 1]

  if (!lastMessage) return ''

  if (lastMessage.user_id == userId) {
    return lastMessage.text
  }
  return lastMessage.user.name + ': ' + lastMessage.text
}
