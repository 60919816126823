import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { modalStyles } from './styles'
import { IconButton, TextField } from '@mui/material'
import LoadingIndicator from '../../common/LoadingIndicator'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import { Close } from '@mui/icons-material'

const ChatBroadcast = () => {
  const { loading, data } = useFetch(`${urlApi}/admin/broadcast/chat`)
  const [activeBroadcasts, setActiveBroadcasts] = useState([])
  const [inactiveBroadcasts, setInactiveBroadcasts] = useState([])
  const [createModal, setCreateModal] = useState(false)

  useEffect(() => {
    if (!loading && data?.length > 0) {
      setActiveBroadcasts(data.filter(broadcast => broadcast.active))
      setInactiveBroadcasts(data.filter(broadcast => !broadcast.active))
    }
  }, [loading, data])

  return (
    <>
      {loading ? (
        <div className='w-full h-screen flex items-center justify-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className='flex flex-row justify-between w-full h-full p-10'>
          <div className='h-[90vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <div className='flex gap-6 mb-4 items-center'>
              <p className='text-xl font-bold'>Chats activos</p>
              <p
                className='text-lg text-light_coral cursor-pointer'
                onClick={() => setCreateModal(true)}
              >
                Crear chat
              </p>
            </div>
            {activeBroadcasts.length === 0 ? (
              <p>No hay chats activos</p>
            ) : (
              activeBroadcasts.map(broadcast => <BroadcastCard broadcast={broadcast} />)
            )}
          </div>
          <div className='h-[90vh] w-1/2 flex flex-col items-center overflow-auto px-3'>
            <p className='text-xl font-bold mb-4'>Chats desactivados</p>
            {inactiveBroadcasts.length === 0 ? (
              <p>No hay chats desactivados</p>
            ) : (
              inactiveBroadcasts.map(broadcast => <BroadcastCard broadcast={broadcast} />)
            )}
          </div>
        </div>
      )}
      {createModal && (
        <CreateModal setActiveBroadcasts={setActiveBroadcasts} setCreateModal={setCreateModal} />
      )}
    </>
  )
}

const BroadcastCard = ({ broadcast }) => {
  const [active, setActive] = useState(broadcast.active)
  const [update, setUpdate] = useState(false)
  const date = new Date(broadcast.date).toLocaleDateString()

  const handleToggle = () => {
    if (window.confirm('¿Activar/Desactivar chat?')) {
      setUpdate(true)
    }
  }

  return (
    <div className='w-full rounded-[12px] flex flex-col p-6 bg-white shadow-xl gap-8 mb-3'>
      <div className='flex justify-between items-center w-full'>
        <p className='max-w-2/3'>{broadcast.message}</p>
        <p className='font-bold'>{date}</p>
      </div>
      <div className='flex flex-row w-full justify-between'>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Enviado</p>
          <p>{broadcast.sent_to}</p>
        </div>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Leido</p>
          <p>{broadcast.read_by}</p>
        </div>
        <div className='flex flex-col items-center text-xl'>
          <p className='font-bold'>Open Rate</p>
          <p>{((broadcast.read_by / broadcast.sent_to) * 100).toFixed(2)}%</p>
        </div>
      </div>
      <div className='w-full flex items-end justify-between'>
        {broadcast.cohort_id ? (
          <p className='font-bold'>Cohort Id: {broadcast.cohort_id}</p>
        ) : (
          <div />
        )}
        {update ? (
          <Update
            channelId={broadcast.channel_id}
            active={active}
            setUpdate={setUpdate}
            setActive={setActive}
          />
        ) : (
          <ToggleSwitch checked={active} handleToggle={handleToggle} />
        )}
      </div>
    </div>
  )
}

const ToggleSwitch = ({ checked, handleToggle }) => {
  return (
    <label className={'relative inline-flex items-center cursor-pointer'}>
      <div
        onClick={handleToggle}
        className={`ring-0 rounded-full ${
          checked ? 'bg-light_coral after:bg-white after:translate-x-[19px]' : 'bg-[#E8E8E8]'
        }  after:bg-[#ABABAB] outline-none duration-300 after:duration-300 w-[50px] h-[30px] shadow-md peer-focus:outline-none after:rounded-full after:absolute after:outline-none after:h-[20px] after:w-[20px] after:top-[5px] after:left-[6px] after:flex after:justify-center after:items-center peer-hover:after:scale-95 after:content-[""]`}
      />
    </label>
  )
}

const CreateModal = ({ setActiveBroadcasts, setCreateModal }) => {
  const [text, setText] = useState('')
  const [image, setImage] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [buttonUrl, setButtonUrl] = useState('')
  const [cohortId, setCohortId] = useState()
  const [create, setCreate] = useState(false)

  const handleClose = () => {
    setCreateModal(false)
  }

  const handleSuccess = data => {
    setActiveBroadcasts(prev => [data, ...prev])
    setCreate(false)
    setCreateModal(false)
  }

  const handleError = () => {
    alert('Hubo un error')
    setCreate(false)
  }

  return (
    <Modal style={modalStyles} isOpen={true} onRequestClose={handleClose}>
      <div className='flex flex-col justify-between h-full'>
        <div className='flex flex-col gap-3 mb-4'>
          <div className='flex justify-between'>
            <p className='text-xl font-bold'>Crear chat</p>
            <IconButton onClick={handleClose} style={{ paddingTop: '0' }}>
              <Close />
            </IconButton>
          </div>
          <p className='font-bold'>Mensaje (al menos uno)</p>
          <TextField
            multiline
            label='Texto'
            value={text}
            onChange={evt => setText(evt.target.value)}
          />
          <TextField label='Imagen' value={image} onChange={evt => setImage(evt.target.value)} />
          <p className='font-bold'>Botón (opcional)</p>
          <TextField
            label='Texto del Botón'
            value={buttonText}
            onChange={evt => setButtonText(evt.target.value)}
          />
          <TextField
            label='Url del Botón'
            value={buttonUrl}
            onChange={evt => setButtonUrl(evt.target.value)}
          />
          <p className='font-bold'>Cohort (opcional)</p>
          <TextField
            type='number'
            label='Cohort Id (opcional)'
            value={cohortId}
            onChange={evt => setCohortId(evt.target.value)}
          />
        </div>
        {create ? (
          <div className='flex w-full items-center justify-center'>
            <Create
              message={{ text, image, button_text: buttonText, button_url: buttonUrl }}
              cohortId={cohortId}
              handleSuccess={handleSuccess}
              handleError={handleError}
            />
          </div>
        ) : (
          <button
            className={`bg-light_coral text-white rounded-lg p-2 ${!text && !image && 'bg-gray'}`}
            onClick={() => setCreate(true)}
            disabled={!text && !image}
          >
            Crear
          </button>
        )}
      </div>
    </Modal>
  )
}

const Create = ({ message, cohortId, handleSuccess, handleError }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/broadcast/chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      message,
      cohort_id: cohortId
    })
  })

  useEffect(() => {
    if (responseOk && !Array.isArray(data)) {
      handleSuccess(data)
    } else if (!loading && !responseOk) {
      handleError()
    }
  }, [loading, responseOk, data])

  return <LoadingIndicator />
}

const Update = ({ channelId, active, setUpdate, setActive }) => {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/broadcast/chat/${channelId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      active: !active
    })
  })

  useEffect(() => {
    if (responseOk) {
      setActive(!active)
      setUpdate(false)
    } else if (!loading) {
      alert('Hubo un error')
      setUpdate(false)
    }
  }, [loading, responseOk])

  return <LoadingIndicator />
}

export default ChatBroadcast
