import { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import useFetch from '../../hooks/useFetch'
import { CHANNELS_MAX, SOCKET_URI } from '../../config/Chat'
import { urlApi } from '../../config'
import Modal from 'react-modal'
import { customStyles } from './styles'
import ChannelList from '../ChannelList'
import ChannelNotSelected from '../ChannelNotSelected'
import { Alert, IconButton, MenuItem, Select, Snackbar, Tooltip } from '@mui/material'
import * as CommonAlert from '../../common/Alert'
import UserInfo from '../../common/UserInfo'
import { ArrowBackIos, ArrowForwardIos, Brightness1, InfoOutlined } from '@mui/icons-material'
import Channel from '../Channel'
import GroupImage from '../GroupImage'
import { io } from 'socket.io-client'
import { sortChannels } from '../../utils'
import LobbyInfo from '../../common/LobbyInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import Tags, { color, label } from './Tags'

const Messages = ({ visible, setVisible, initialLobbyId, locale }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [channels, setChannels] = useState()
  const [selectedChannel, setSelectedChannel] = useState()
  const [connect, setConnect] = useState(false)
  const [socketDisconnected, setSocketDisconnected] = useState(false)
  const [refetchChannels, setRefetchChannels] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [lobby, setLobby] = useState({})
  const [filter, setFilter] = useState('all')
  const socket = useRef()
  const { loading, responseOk, data } = useFetch(`${urlApi}/admin/channels/connect`, {
    method: 'POST'
  })
  const {
    loading: loadingChannels,
    responseOk: responseOkChannels,
    data: dataChannels
  } = useFetch(
    `${urlApi}/admin/channels?offset=0&lobby_id=${initialLobbyId}&locale=${locale ?? 'uy'}`
  )

  useEffect(() => {
    if (responseOk) {
      socket.current = io(`${SOCKET_URI}?authentication=${data.token}`, {
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionAttempts: Infinity
      })
      setConnect(true)
    } else if (!loading) {
      setChannels([])
    }
  }, [loading])

  useEffect(() => {
    if (connect && responseOkChannels) {
      setChannels(dataChannels)
    } else if (!loadingChannels) {
      setChannels([])
    }
  }, [loadingChannels, connect])

  useEffect(() => {
    if (socket.current && channels && channels.length > 0) {
      socket.current.on('receive', data => receiveMessage(data))

      socket.current.on('disconnect', () => {
        setSocketDisconnected(true)
      })

      socket.current.on('connect', () => {
        if (socketDisconnected) {
          if (selectedChannel) {
            const channel = channels.find(channel => channel._id === selectedChannel._id)
            if (channel) {
              setChannels(
                changeChannels(
                  {
                    _id: selectedChannel._id,
                    message: channel.messages.map(message => {
                      return {
                        ...message,
                        not_sent: false
                      }
                    })
                  },
                  channels
                )
              )
            }
          }
          setRefetchChannels(true)
          setSocketDisconnected(false)
        }
      })
    }

    return () => {
      if (socket.current) {
        socket.current.off('receive')
        socket.current.off('disconnect')
        socket.current.off('connect')
      }
    }
  }, [socket, channels, socketDisconnected])

  const receiveMessage = data => {
    const channel = channels.find(channel => channel._id === data.channel_id)
    if (!channel) return
    setChannels(
      changeChannels(
        {
          _id: channel._id,
          messages: [...channel.messages, data]
        },
        channels
      )
    )
  }

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  useEffect(() => {
    if (!dataChannels || dataChannels.length === 0) return
    if (filter === 'all') {
      setChannels(dataChannels)
    } else if (filter === 'unread') {
      setChannels(
        dataChannels.filter(channel =>
          channel.messages.some(
            message => message.user_id !== 'admin' && !message.read_by.includes('admin')
          )
        )
      )
    } else if (filter === 'lobbygroups') {
      setChannels(dataChannels.filter(channel => channel.type === 'lobbygroup'))
    } else if (filter === 'direct-messages') {
      setChannels(dataChannels.filter(channel => channel.type === 'direct-message'))
    } else if (filter === 'unread-channels') {
      const unreadChannels = dataChannels.filter(channel => {
        const driver = channel.users.find(user => user.role === 'driver')
        return channel.messages.some(
          message =>
            message.user_id !== driver.user_id && !message.read_by?.includes(driver.user_id)
        )
      })
      setChannels(unreadChannels)
    }
  }, [filter])

  return (
    <>
      <Modal isOpen={visible} style={customStyles} onRequestClose={() => setVisible(false)}>
        {width >= 768 ? (
          <div className='flex flex-col w-full h-full gap-2 hidden md:inline-block'>
            <DesktopHeader
              setVisible={setVisible}
              selectedChannel={selectedChannel}
              initialLobbyId={initialLobbyId}
              channels={channels}
              setChannels={setChannels}
              filter={filter}
              setFilter={setFilter}
            />
            {selectedChannel && <UsersInfo channel={selectedChannel} />}
            <GoToLobbyButton lobbyId={selectedChannel?.lobby_id} setLobby={setLobby} />
            <div className='flex flex-row h-full'>
              <ChannelList
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                channels={channels}
                setRefetchChannels={setRefetchChannels}
                refetchChannels={refetchChannels}
              />
              {selectedChannel ? (
                <Channel
                  selectedChannel={selectedChannel}
                  setSelectedChannel={setSelectedChannel}
                  selectedChannelId={selectedChannel?._id}
                  socket={socket}
                  setChannels={setChannels}
                  channels={channels}
                  socketDisconnected={socketDisconnected}
                  setErrorMessage={setErrorMessage}
                />
              ) : (
                <ChannelNotSelected />
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col w-full h-full md:hidden'>
            <MobileHeader
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              setVisible={setVisible}
              initialLobbyId={initialLobbyId}
            />
            <GoToLobbyButton lobbyId={selectedChannel?.lobby_id} setLobby={setLobby} />
            {selectedChannel ? (
              <Channel
                selectedChannel={selectedChannel}
                selectedChannelId={selectedChannel?._id}
                socket={socket}
                setChannels={setChannels}
                channels={channels}
                socketDisconnected={socketDisconnected}
                setErrorMessage={setErrorMessage}
              />
            ) : (
              <ChannelList
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                channels={channels}
              />
            )}
          </div>
        )}
        {refetchChannels && (
          <RefetchChannels
            setChannels={setChannels}
            setRefetchChannels={setRefetchChannels}
            initialLobbyId={initialLobbyId}
            channels={channels}
            locale={locale}
          />
        )}
        <Snackbar open={errorMessage} autoHideDuration={3000} onClose={() => setErrorMessage(null)}>
          <Alert
            sx={{ fontSize: 12, display: 'flex', alignItems: 'center' }}
            elevation={6}
            variant='filled'
            severity='error'
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Modal>
      {Object.keys(lobby).length > 0 && <LobbyInfo lobby={lobby} setSelected={setLobby} />}
    </>
  )
}

export default Messages

const MobileHeader = ({ selectedChannel, setSelectedChannel, setVisible, initialLobbyId }) => {
  const directMessageName = selectedChannel?.users.map(user => user.name).join(' y ')

  return (
    <div className={`flex flex-row gap-4 items-center w-full p-3 min-h-[4.5rem]`}>
      <BackButton
        setVisible={setVisible}
        setSelectedChannel={setSelectedChannel}
        selectedChannel={selectedChannel}
      />
      {selectedChannel ? (
        <>
          <GroupImage images={selectedChannel.users.map(user => user.picture)} />
          <p className='text-base text-primary'>{selectedChannel.name ?? directMessageName}</p>
        </>
      ) : (
        <p className={`text-primary text-xl font-bold`}>
          Mensajes {initialLobbyId && 'de ' + initialLobbyId}
        </p>
      )}
    </div>
  )
}

const DesktopHeader = ({
  setVisible,
  selectedChannel,
  initialLobbyId,
  channels,
  setChannels,
  filter,
  setFilter
}) => {
  const [tagModal, setTagModal] = useState(false)
  const directMessageName = selectedChannel?.users.map(user => user.name).join(' y ')

  const openTagsModal = () => {
    setTagModal(true)
  }

  const handleChangeChannels = change => {
    setChannels(changeChannels(change, channels))
  }

  return (
    <>
      <div className='flex flex-row items-center justify-between w-full p-3 min-h-[4.6rem]'>
        <div className='flex flex-col gap-4 w-1/3'>
          <div className='flex flex-row gap-4'>
            <BackButton setVisible={setVisible} />
            <p className='font-bold text-xl text-primary'>
              Mensajes {initialLobbyId && 'de ' + initialLobbyId}
            </p>
          </div>
          <Filters filter={filter} setFilter={setFilter} />
        </div>
        {selectedChannel && (
          <div className='flex items-center gap-4 w-2/3'>
            <GroupImage images={selectedChannel.users.map(user => user.picture)} />
            <p className='text-lg text-primary'>{selectedChannel.name ?? directMessageName}</p>
            <FontAwesomeIcon
              icon={faTag}
              className='text-xl text-light_coral cursor-pointer'
              onClick={openTagsModal}
            />
            {selectedChannel?.tags?.map(tag => (
              <Tooltip title={label(tag)}>
                <div
                  style={{ backgroundColor: color(tag) }}
                  className='w-5 h-5 rounded-full border-black border-2'
                />
              </Tooltip>
            ))}
          </div>
        )}
      </div>
      {selectedChannel && (
        <Tags
          tagModal={tagModal}
          setTagModal={setTagModal}
          channel={selectedChannel}
          handleChangeChannels={handleChangeChannels}
        />
      )}
    </>
  )
}

const BackButton = ({ setVisible, setSelectedChannel, selectedChannel }) => {
  return (
    <IconButton
      onClick={() => {
        if (selectedChannel) setSelectedChannel(null)
        else setVisible(false)
      }}
      sx={{ color: '#d65944', paddingTop: 0, paddingBottom: 0 }}
    >
      <ArrowBackIos />
    </IconButton>
  )
}

const changeChannels = (change, channels) => {
  const newChannels = channels.map(channel => {
    if (channel._id === change._id) {
      return {
        ...channel,
        ...change
      }
    } else {
      return channel
    }
  })
  return sortChannels(newChannels)
}

const RefetchChannels = ({ setChannels, setRefetchChannels, channels, initialLobbyId, locale }) => {
  const { loading, responseOk, data } = useFetch(
    `${urlApi}/admin/channels?offset=0&lobby_id=${initialLobbyId}&locale=${locale ?? 'uy'}`
  )

  useEffect(() => {
    if (responseOk) {
      setChannels(channels.concat(data))
      setRefetchChannels(false)
    }
  }, [loading])

  return null
}

const GoToLobbyButton = ({ lobbyId, setLobby }) => {
  const [getLobby, setGetLobby] = useState(false)

  const handleClick = () => {
    setGetLobby(true)
  }

  if (!lobbyId) return null

  return (
    <>
      {!getLobby ? (
        <div className='w-full flex flex-row'>
          <div className='w-1/3 hidden md:inline-block' />
          <div
            className='flex flex-row items-center justify-between p-4 w-full md:w-2/3 self-end cursor-pointer'
            onClick={handleClick}
          >
            <div className='flex flex-row gap-4'>
              <InfoOutlined sx={{ color: '#d65944' }} />
              <p className='text-primary text-base'>Ir al viaje</p>
            </div>
            <ArrowForwardIos sx={{ color: '#d65944', fontSize: 16 }} />
          </div>
        </div>
      ) : (
        <GetLobby lobbyId={lobbyId} setLobby={setLobby} setGetLobby={setGetLobby} />
      )}
    </>
  )
}

const GetLobby = ({ lobbyId, setLobby, setGetLobby }) => {
  const { loading, data, responseOk } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)
  useEffect(() => {
    if (!loading && responseOk) {
      setLobby(data)
      setGetLobby(false)
    }
  }, [data])
  return (
    <div className='flex justify-center items-center'>
      <p>Cargando...</p>
    </div>
  )
}

const UsersInfo = ({ channel }) => {
  const [userAlert, setUserAlert] = useState(false)

  return (
    <div>
      <div className='flex flex-row'>
        <div className='w-1/3 hidden md:inline-block' />
        <div className='flex flex-row gap-4'>
          {channel.users.map(user => (
            <div
              className='flex items-center gap-2 cursor-pointer hover:bg-light_gray'
              onClick={() => setUserAlert(user.user_id)}
            >
              <img className='w-7 h-7 rounded-full' src={user.picture} alt={user.name} />
              <div>
                <h3
                  className={`font-bold ${
                    user.blocked ? 'text-red-600' : !user.active ? 'text-gray' : 'text black'
                  }`}
                >
                  {user.name}
                </h3>
                {user.blocked && <p className='text-xs text-red-600'>Bloqueado</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
      {userAlert && (
        <CommonAlert.default
          hideButton
          visible={userAlert}
          onClose={() => setUserAlert(false)}
          style={{ height: 510 }}
        >
          <UserInfo userId={userAlert} lobby={''} />
        </CommonAlert.default>
      )}
    </div>
  )
}

const Filters = ({ filter, setFilter }) => {
  return (
    <Select
      value={filter}
      size='small'
      placeholder='Seleccionar filtro'
      onChange={evt => setFilter(evt.target.value)}
    >
      <MenuItem value='all'>Todos</MenuItem>
      <MenuItem value='unread'>No leido</MenuItem>
      <MenuItem value='lobbygroups'>Chats de grupo</MenuItem>
      <MenuItem value='direct-messages'>Chats de consulta</MenuItem>
      <MenuItem value='unread-channels'>No leido por el conductor</MenuItem>
    </Select>
  )
}
