import useFetch from '../../hooks/useFetch'
import { useState } from 'react'
import Modal from 'react-modal'
import { urlApi } from '../../config'
import CompanionCard from '../CompanionCard'
import CompanionInfo from '../CompanionInfo'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import LoadingIndicator from '../LoadingIndicator'

export default ({ lobby, setMatchesCompanions }) => {
  const [visible, setVisible] = useState(true)
  const [selectedCompanion, setSelectedCompanion] = useState(false)
  const { loading, data, responseOk } = useFetch(`${urlApi}/admin/lobbies/matches/${lobby._id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => {
        setMatchesCompanions(false)
        setVisible(false)
      }}
      style={customStyles}
      contentLabel='Example Modal'
    >
      {loading ? (
        <div className='h-full justify-center items-center flex text-2xl text-center'>
          <LoadingIndicator />
        </div>
      ) : !responseOk ? (
        <div className='text-red-600 font-extrabold text-2xl'>Error al buscar coincidencias</div>
      ) : (
        <div className='px-4'>
          <div className='flex justify-between items-center'>
            <IconButton
              onClick={() => {
                setMatchesCompanions(false)
                setVisible(false)
              }}
            >
              <Close />
            </IconButton>
            <h2 className='pt-6 text-2xl text-orange-700 pb-5 text-center'>
              Cantidad: {data.length}
            </h2>
            <div />
          </div>
          <h2 className='text-2xl text-orange-700 pb-5 text-center'>
            Coincidencias de acompañantes
          </h2>
          <div className='h-screen overflow-auto pb-10'>
            {data.map(companion => (
              <CompanionCard
                companion={companion}
                setSelected={setSelectedCompanion}
                recommendatedLobby={lobby}
              />
            ))}
            <div className='text-xl text-center mb-64'>FIN</div>
          </div>
        </div>
      )}
      {Object.keys(selectedCompanion).length != 0 && (
        <CompanionInfo companion={selectedCompanion} setSelected={setSelectedCompanion} />
      )}
    </Modal>
  )
}
