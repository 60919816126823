import { Avatar, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import UserInfo from './UserInfo'

const UserSearch = ({ defaultId }) => {
  const [text, setText] = useState(defaultId ? defaultId : '')
  const [search, setSearch] = useState(defaultId ? true : false)

  useEffect(() => {
    const handleEnter = e => {
      if (e.key === 'Enter' && e.shiftKey === false) {
        setSearch(false)
        setTimeout(() => {
          setSearch(true)
        }, 100)
      }
    }
    document.addEventListener('keydown', handleEnter)
    return () => {
      document.removeEventListener('keydown', handleEnter)
    }
  }, [text])

  return (
    <div className='flex flex-col bg-gray-200 rounded-xl px-7 py-5 overflow-auto md:mx-6 h-screen pb-20'>
      <div className='flex flex-row text-center justify-between items-center'>
        <TextField
          label='Usuario'
          variant='outlined'
          value={text}
          onChange={e => {
            let val = e.target.value

            if (Number(val.charAt(1))) {
              val = val.replace(/\s+/g, '').replace(/[-‐‑‒–—]/g, '')
            }

            if (val.startsWith('+549')) {
              val = val.replace('+549', '+54')
            }

            setText(val)
          }}
          className='w-1/2'
        />
        <button
          className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#FF0000] text-[#fff] w-1/6 h-10'
          onClick={() => setSearch(false)}
        >
          Cancelar
        </button>
        <button
          className='bg-gray-300 rounded-md py-1 px-3 shadow-2xl mb-2 bg-[#d65944] text-[#fff] w-1/6 h-10'
          onClick={() => {
            setSearch(false)
            setTimeout(() => {
              setSearch(true)
            }, 100)
          }}
        >
          Buscar
        </button>
      </div>
      {search && <UsersRequest text={text} defaultId={defaultId} />}
    </div>
  )
}

const UsersRequest = ({ text, defaultId }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/users/${text}`)
  const [userInfo, setUserInfo] = useState()

  useEffect(() => {
    if (defaultId && data.length > 0) {
      setUserInfo(data[0])
    }
  }, [data])

  return (
    <div>
      {loading ? (
        <>Cargando...</>
      ) : userInfo ? (
        <UserInfo userInfo={userInfo} setUserInfo={setUserInfo} />
      ) : data.status == 404 || data.status == 500 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>Error en la busqueda</p>
        </div>
      ) : data.length == 0 ? (
        <div className='flex flex-col justify-center items-center mt-10'>
          <p className='text-2xl text-center'>No se encontró ningún usuario</p>
        </div>
      ) : (
        <div>
          {data.map((user, index) => (
            <div
              className='flex flex-row justify-between items-center cursor-pointer bg-gray-300 w-full my-2 py-2 px-3 rounded-xl text-sm'
              onClick={() => setUserInfo(user)}
            >
              <div className='flex flex-row items-center'>
                <Avatar src={user.picture} sx={{ width: 56, height: 56, marginRight: 2 }} />
                <div className='flex flex-col'>
                  <p>
                    {user?.user_metadata?.first_name} {user?.user_metadata?.last_name}
                  </p>
                  <p>{user?.user_id}</p>
                </div>
              </div>
              <p>{user?.user_metadata?.phone_number}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default UserSearch
