import { DIAS, MESES } from '../../config'
/**
 * Devuelve un objeto con las partes formateadas (día, mes, año, hora)
 * ajustado a la hora de Uruguay (UTC-3).
 */
export const formatDateTimeInUruguay = dateString => {
  const originalDate = new Date(dateString)
  const offsetDate = new Date(originalDate.getTime() - 3 * 60 * 60 * 1000)
  const isoString = offsetDate.toISOString()

  const time = isoString.substr(isoString.indexOf('T') + 1, 5)
  const dayName = DIAS[offsetDate.getDay()]
  const day = offsetDate.getDate()
  const monthName = MESES[offsetDate.getMonth()]
  const year = offsetDate.getFullYear()

  return { time, dayName, day, monthName, year }
}

/** Calcula la edad a partir de la fecha de nacimiento */
export const calculateAge = birthdateString => {
  if (!birthdateString) return ''
  const birthdate = new Date(birthdateString)
  const today = new Date()
  let age = today.getFullYear() - birthdate.getFullYear()
  const month = today.getMonth() - birthdate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
    age--
  }
  return age
}

/** Corrige el número de teléfono si no empieza con '+' */
export const fixPhoneNumber = userInfo => {
  const { phone_number } = userInfo.user_metadata
  // Si no empieza con '+', agregamos el prefijo de Uruguay +598
  if (phone_number.charAt(0) !== '+') {
    userInfo.user_metadata.phone_number = `+598${phone_number.substring(1)}`
  }
}

export const formatText = text => {
  //add br
  const textWithBr = text.replace(/\n/g, '<br />')
  return textWithBr
}
