export const modalStyles = {
  content: {
    borderRadius: '1.5rem',
    height: 'fit-content',
    maxHeight: 700,
    maxWidth: 600,
    margin: 'auto',
    paddingTop: '2.5rem',
    paddingLeft: '2rem'
  },
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
}
