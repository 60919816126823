export const urlApi = process.env.REACT_APP_URL_API

export const urlApp = process.env.REACT_APP_URL_APP

export const GETSTREAM_KEY = process.env.REACT_APP_GETSTREAM_KEY

export const GETSTREAM_TOKEN = process.env.REACT_APP_GETSTREAM_TOKEN

export const DIAS = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']

export const MESES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]
