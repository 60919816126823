import { Divider, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { ArrowBackIosNew, Close } from '@mui/icons-material'
import Modal from 'react-modal'
import LobbyInfo from '../../common/LobbyInfo'
import LobbyCard from '../../common/LobbyCard'
import Alert from '../../common/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPenToSquare, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { formatDateTimeInUruguay, calculateAge, fixPhoneNumber, formatText } from './utils'
import LoadingIndicator from '../../common/LoadingIndicator'

export default function UserInfo({ userInfo, setUserInfo }) {
  useEffect(() => {
    userInfo.user_metadata.first_name = userInfo.user_metadata.first_name.trim()
    fixPhoneNumber(userInfo)
  }, [userInfo])

  const created = formatDateTimeInUruguay(userInfo.created_at)
  const lastLogin = formatDateTimeInUruguay(userInfo.last_login)

  const [userLobbiesVisible, setUserLobbiesVisible] = useState(false)
  const [verifyUserAlert, setVerifyUserAlert] = useState(false)
  const [verifyUser, setVerifyUser] = useState(false)
  const [declineVerificationAlert, setDeclineVerificationAlert] = useState(false)
  const [declineVerification, setDeclineVerification] = useState(false)
  const [declineReason, setDeclineReason] = useState('profile-picture')
  const [declineReasonText, setDeclineReasonText] = useState('')
  const [blockUserAlert, setBlockUserAlert] = useState(false)
  const [blockUser, setBlockUser] = useState(false)
  const [unblockUserAlert, setUnblockUserAlert] = useState(false)
  const [unblockUser, setUnblockUser] = useState(false)
  const [commentAlert, setCommentAlert] = useState(false)
  const [comment, setComment] = useState(userInfo.user_metadata.admin_comment || '')
  const [sendComment, setSendComment] = useState(false)

  const age = calculateAge(userInfo.user_metadata.birthdate)

  return (
    <div>
      <ArrowBackIosNew className='cursor-pointer mt-3' onClick={() => setUserInfo()} />
      <div className='flex flex-col md:flex-row gap-3 pt-6'>
        <UserDetailsCard
          userInfo={userInfo}
          created={created}
          lastLogin={lastLogin}
          onBlockUser={() => setBlockUserAlert(true)}
          onUnblockUser={() => setUnblockUserAlert(true)}
          onComment={() => setCommentAlert(true)}
          comment={comment}
        />
        <UserMetadataCard
          userInfo={userInfo}
          age={age}
          onShowUserLobbies={() => setUserLobbiesVisible(true)}
        />
      </div>
      <UserActions
        userInfo={userInfo}
        onVerifyUserAlert={() => setVerifyUserAlert(true)}
        onDeclineVerificationAlert={() => setDeclineVerificationAlert(true)}
      />
      {userLobbiesVisible && (
        <UserLobbies
          userLobbiesVisible={userLobbiesVisible}
          setUserLobbiesVisible={setUserLobbiesVisible}
          userId={userInfo.user_id}
        />
      )}
      {verifyUserAlert && (
        <Alert
          onSubmit={() => setVerifyUser(true)}
          onClose={() => setVerifyUserAlert(false)}
          visible={verifyUserAlert}
        >
          <p className='font-bold'>Verificar usuario</p>
        </Alert>
      )}
      {verifyUser && <VerifyUser setVerifyUser={setVerifyUser} userId={userInfo.user_id} />}
      {declineVerificationAlert && (
        <Alert
          onSubmit={() => setDeclineVerification(true)}
          onClose={() => setDeclineVerificationAlert(false)}
          visible={declineVerificationAlert}
          disabled={!declineReason || (declineReason === 'other' && !declineReasonText)}
        >
          <div className='flex flex-col gap-6 mb-6'>
            <p>¿Seguro que querés rechazar la verificación del usuario?</p>
            <Select
              value={declineReason}
              size='small'
              placeholder='Seleccionar motivo'
              onChange={evt => setDeclineReason(evt.target.value)}
            >
              <MenuItem value='profile-picture'>Falta foto de perfil</MenuItem>
              <MenuItem value='image'>Imagen poco clara / invalida</MenuItem>
              <MenuItem value='profile-name'>Falta nombre en perfil</MenuItem>
              <MenuItem value='profile-contact'>Info de contacto en perfil</MenuItem>
              <MenuItem value='other'>Otro</MenuItem>
            </Select>
            {declineReason === 'other' && (
              <TextField
                label='Motivo'
                variant='outlined'
                value={declineReasonText}
                onChange={e => setDeclineReasonText(e.target.value)}
              />
            )}
          </div>
        </Alert>
      )}
      {declineVerification && (
        <DeclineVerification
          setDeclineVerification={setDeclineVerification}
          userId={userInfo.user_id}
          reason={declineReason === 'other' ? declineReasonText : declineReason}
        />
      )}
      {blockUserAlert && (
        <Alert
          onSubmit={() => setBlockUser(true)}
          onClose={() => setBlockUserAlert(false)}
          visible={blockUserAlert}
        >
          <p className='font-bold'>Bloquear usuario</p>
        </Alert>
      )}
      {blockUser && (
        <BlockUser
          setBlockUser={setBlockUser}
          userId={userInfo.user_id}
          handleSuccess={() => (userInfo.blocked = true)}
        />
      )}
      {unblockUserAlert && (
        <Alert
          onSubmit={() => setUnblockUser(true)}
          onClose={() => setUnblockUserAlert(false)}
          visible={unblockUserAlert}
        >
          <p className='font-bold'>Desbloquear usuario</p>
        </Alert>
      )}
      {unblockUser && (
        <UnblockUser
          setUnblockUser={setUnblockUser}
          userId={userInfo.user_id}
          handleSuccess={() => (userInfo.blocked = false)}
        />
      )}
      {commentAlert && (
        <Alert
          onClose={() => {
            setComment(userInfo.user_metadata.admin_comment || '')
            setCommentAlert(false)
          }}
          onSubmit={() => setSendComment(true)}
          visible={commentAlert}
          onlyConfirm
          disabled={sendComment}
        >
          <div className='flex flex-col mb-6'>
            <TextField
              label='Comentario'
              variant='outlined'
              value={comment}
              multiline
              onChange={e => setComment(e.target.value)}
            />
            <p className='font-bold text-xs text-gray text-start mt-2 ml-1'>
              Shift+Enter para nueva linea
            </p>
          </div>
        </Alert>
      )}
      {sendComment && (
        <SendComment
          setSendComment={setSendComment}
          userId={userInfo.user_id}
          comment={comment}
          handleSuccess={() => {
            userInfo.user_metadata.admin_comment = comment
            setCommentAlert(false)
          }}
        />
      )}
    </div>
  )
}

function UserDetailsCard({
  userInfo,
  created,
  lastLogin,
  onBlockUser,
  onUnblockUser,
  onComment,
  comment
}) {
  return (
    <div className='flex flex-col justify-center items-center gap-3 text-center w-full md:w-1/2 lg:w-[40%]'>
      <div className='flex flex-col justify-center items-center gap-3 text-center border-[1px] border-gray rounded-lg p-5 w-full relative'>
        <img
          className='rounded-full mt-3 w-[150px] h-[150px]'
          width='150'
          height='150'
          src={userInfo.picture}
          alt='User profile'
        />
        <p>{userInfo.user_id}</p>
        <Divider sx={{ width: '100%' }} />
        <div
          className='flex flex-row items-center m-auto cursor-pointer'
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send/?phone=${userInfo.user_metadata.phone_number}&text=%20`
            )
          }
        >
          <WhatsAppIcon className='cursor-pointer text-green-600' />
          <p className='font-bold ml-1'>{userInfo.user_metadata.phone_number}</p>
        </div>

        <Divider sx={{ width: '100%' }} />
        <p className='m-auto'>{userInfo.email || userInfo.user_metadata.email}</p>
        {userInfo.blocked ? (
          <div className='absolute top-6 right-6 cursor-pointer' onClick={onUnblockUser}>
            <FontAwesomeIcon icon={faRotateLeft} className='text-primary w-6 h-6' />
          </div>
        ) : (
          <div className='absolute top-6 right-6 cursor-pointer' onClick={onBlockUser}>
            <FontAwesomeIcon icon={faBan} className='text-primary w-6 h-6' />
          </div>
        )}
      </div>
      <div className='flex flex-col justify-center items-center gap-3 text-center border-[1px] border-gray rounded-lg p-5 w-full'>
        {userInfo?.user_metadata?.verified === 'pending' ? (
          <p className='text-center text-primary'>Usuario pendiente de verificación</p>
        ) : (
          userInfo?.user_metadata.verified === true && (
            <p className='font-bold'>Usuario Verificado</p>
          )
        )}
        {userInfo.blocked && <p className='text-center font-bold text-error'>Usuario bloqueado</p>}
        <p>
          <span className='font-bold'>Registrado: </span>
          {`${created.dayName} ${created.day} de ${created.monthName} ${created.year}`}{' '}
          {created.time}
        </p>
        <p>
          <span className='font-bold'>Último ingreso: </span>
          {`${lastLogin.dayName} ${lastLogin.day} de ${lastLogin.monthName} ${lastLogin.year}`}{' '}
          {lastLogin.time}
        </p>
        <div className='cursor-pointer' onClick={onComment}>
          <p className='font-bold text-center mb-2'>
            Comentario <FontAwesomeIcon icon={faPenToSquare} className='text-primary' />
          </p>
          <p className='text-start'>
            {comment === '' ? (
              <span className='text-gray'>No hay comentario</span>
            ) : (
              comment.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

function UserMetadataCard({ userInfo, age, onShowUserLobbies }) {
  return (
    <div className='flex flex-col gap-3 border-[1px] border-gray rounded-lg p-6 grow w-full md:w-1/2 lg:w-[60%] justify-between'>
      <p>
        <span className='font-bold'>Nombre: </span> {userInfo.user_metadata.first_name}
      </p>
      <p>
        <span className='font-bold'>Apellido: </span>
        {userInfo.user_metadata.last_name}
      </p>
      <p>
        <span className='font-bold'>Edad: </span>
        {age} {age ? 'años' : ''}
      </p>
      <p>
        <span className='font-bold'>Género: </span>
        {userInfo.user_metadata.gender}
      </p>
      <p>
        <span className='font-bold'>Preferencias: </span>
        {userInfo.user_metadata.preferences?.join(' | ')}
      </p>
      <p>
        <span className='font-bold'>Biografía: </span>
        {userInfo.user_metadata.biography}
      </p>
      {userInfo.user_metadata.car && (
        <p>
          <span className='font-bold'>Auto: </span>
          {userInfo.user_metadata.car.model}, {userInfo.user_metadata.car.color}
        </p>
      )}
      <p>
        <span className='font-bold'>Ubicaciones preferidas: </span>
        {userInfo.user_metadata.preferred_locations?.map(x => x.location_name || x).join(' | ')}
      </p>
      <p>
        <span className='font-bold'>Frecuencia: </span>
        {userInfo.user_metadata.frequency}
      </p>
      {userInfo.user_metadata.potential_driver && (
        <p>
          <span className='font-bold'>Potencial conductor</span>
        </p>
      )}
      <button
        className='bg-[#d65944] text-white font-bold py-2 px-4 rounded-xl mt-3'
        onClick={onShowUserLobbies}
      >
        Viajes
      </button>
    </div>
  )
}

function UserActions({ userInfo, onVerifyUserAlert, onDeclineVerificationAlert }) {
  const isPending = userInfo.user_metadata.verified === 'pending'
  const isNotVerified = !userInfo.user_metadata.verified || isPending

  return (
    <div className='flex flex-col justify-center mb-3 mt-3'>
      {isNotVerified && (
        <button
          className='bg-light_coral text-white font-bold py-2 px-4 rounded-xl mx-auto mt-6'
          onClick={onVerifyUserAlert}
        >
          Verificar
        </button>
      )}
      {isPending && (
        <button
          className='bg-error text-white font-bold py-2 px-4 rounded-xl mx-auto mt-4'
          onClick={onDeclineVerificationAlert}
        >
          Rechazar Verificación
        </button>
      )}
    </div>
  )
}

function UserLobbies({ userLobbiesVisible, setUserLobbiesVisible, userId }) {
  const { loading, data } = useFetch(`${urlApi}/admin/users/lobbies/${userId}`)
  const [selectedLobby, setSelectedLobby] = useState({})

  const customStyles = {
    content: {
      borderRadius: '1.5rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingLeft: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    overlay: {
      zIndex: 90,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  }

  const sortLobbies = lobbies =>
    lobbies.sort((a, b) => {
      const dateA = a.Journey.departure_time
      const dateB = b.Journey.departure_time
      return dateA > dateB ? -1 : 1
    })

  return (
    <>
      <Modal
        isOpen={userLobbiesVisible}
        onRequestClose={() => setUserLobbiesVisible(false)}
        style={customStyles}
      >
        <div className='flex flex-col h-full'>
          <Close onClick={() => setUserLobbiesVisible(false)} />
          {loading ? (
            <div className='flex h-full items-center justify-center'>
              <LoadingIndicator />
            </div>
          ) : data?.length > 0 ? (
            <ol className='overflow-auto h-screen'>
              {sortLobbies(data).map(lobby => (
                <li key={lobby.id || Math.random()}>
                  <LobbyCard lobby={lobby} setSelected={setSelectedLobby} />
                </li>
              ))}
              <li className='text-xl text-center'>FIN</li>
            </ol>
          ) : (
            <div className='flex justify-center'>
              <p>No hay viajes</p>
            </div>
          )}
        </div>
      </Modal>

      {Object.keys(selectedLobby).length > 0 && (
        <LobbyInfo lobby={selectedLobby} setSelected={setSelectedLobby} />
      )}
    </>
  )
}

// =========================
// VERIFY / DECLINE / BLOCK
// =========================

function VerifyUser({ setVerifyUser, userId }) {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/verify/${userId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        alert('Usuario verificado')
      } else {
        alert('Error al verificar')
      }
      setVerifyUser(false)
    }
  }, [loading])

  return null
}

function DeclineVerification({ setDeclineVerification, userId, reason }) {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/decline-verification/${userId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reason })
  })

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        alert('Usuario rechazado')
      } else {
        alert('Error al rechazar')
      }
      setDeclineVerification(false)
    }
  }, [loading])

  return null
}

function BlockUser({ setBlockUser, userId, handleSuccess }) {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/block/${userId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        alert('Usuario bloqueado')
        handleSuccess()
      } else {
        alert('Error al bloquear')
      }
      setBlockUser(false)
    }
  }, [loading])

  return null
}

function UnblockUser({ setUnblockUser, userId, handleSuccess }) {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/unblock/${userId}`, {
    method: 'POST'
  })

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        alert('Usuario desbloqueado')
        handleSuccess()
      } else {
        alert('Error al desbloquear')
      }
      setUnblockUser(false)
    }
  }, [loading])

  return null
}

function SendComment({ setSendComment, userId, comment, handleSuccess }) {
  const { loading, responseOk } = useFetch(`${urlApi}/admin/users/comment/${userId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ comment })
  })

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        alert('Comentario enviado')
        setSendComment(false)
        handleSuccess()
      } else {
        alert('Error al enviar comentario')
        setSendComment(false)
      }
    }
  }, [loading])

  return null
}
