import useFetch from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import LobbiesList from '../Lobbies/LobbiesList'
import CompanionsList from '../Companions/CompanionsList'
import LobbyInfo from '../../common/LobbyInfo'
import { urlApi } from '../../config'
import { DateRangePicker } from 'mui-daterange-picker'
import Modal from 'react-modal'
import LoadingIndicator from '../../common/LoadingIndicator'
import CompanionInfo from '../../common/CompanionInfo'

export default ({ locale, hidden }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/lobbies?locale=${locale}`, {}, true)
  const { loading: loadingCompanions, data: dataCompanions } = useFetch(
    `${urlApi}/admin/companions?locale=${locale}`
  )
  const [selectedSection, setSelectedSection] = useState('lobbies')
  const [selectedLobby, setSelectedLobby] = useState({})
  const [selectedCompanion, setSelectedCompanion] = useState({})
  const [filters, setFilters] = useState(['active'])
  const [lobbyFilter, setLobbyFilter] = useState('')
  const [originSearch, setOriginSearch] = useState('')
  const [destinationSearch, setDestinationSearch] = useState('')
  const [nameSearch, setNameSearch] = useState('')
  const [dateRange, setDateRange] = useState({})
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [fields, setFields] = useState({
    filters,
    originSearch,
    destinationSearch,
    nameSearch,
    dateRange
  }) //Contendra todos los filtros dentro
  const [length, setLength] = useState('?')

  useEffect(() => {
    setFields({
      filters,
      lobbyFilter,
      nameSearch,
      originSearch,
      destinationSearch,
      dateRange
    })
  }, [filters, originSearch, destinationSearch, nameSearch, dateRange, lobbyFilter])

  const handleFilters = (event, newFilters) => {
    setFilters(newFilters)
  }

  return (
    <div className='w-screen px-6' hidden={hidden}>
      <div className='flex justify-center mt-6'>
        <ToggleButtonGroup size='small' value={filters} onChange={handleFilters}>
          <ToggleButton value='company'>Con acompañantes</ToggleButton>
          <ToggleButton value='nodocumented'>Sin documentar</ToggleButton>
          <ToggleButton value='active'>Viajes nuevos</ToggleButton>
          <ToggleButton value='orderByTime'>Ordenar por tiemp</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className='flex justify-center gap-2 mt-2'>
        <TextField
          size='small'
          label='Nombre/Id'
          onChange={evt => setNameSearch(evt.target.value)}
        />
        <TextField
          size='small'
          label='Origen'
          onChange={evt => setOriginSearch(evt.target.value)}
        />
        <TextField
          size='small'
          label='Destino'
          onChange={evt => setDestinationSearch(evt.target.value)}
        />
      </div>
      <div className='flex justify-between w-full my-4'>
        <Select defaultValue='none' size='small' onChange={evt => setLobbyFilter(evt.target.value)}>
          <MenuItem value={'pending'} sx={{ backgroundColor: '#faedcc' }}>
            Pendiente
          </MenuItem>
          <MenuItem value={'completed'} sx={{ backgroundColor: '#cdeec8' }}>
            Completado
          </MenuItem>
          <MenuItem value={'cancelled'} sx={{ backgroundColor: '#fae3de' }}>
            Cancelado
          </MenuItem>
          <MenuItem value={'not-cancelled'}>No cancelados</MenuItem>
          <MenuItem value={'none'}>Sin filtro</MenuItem>
        </Select>
        <div className='flex'>
          <button
            className='bg-gray-300 rounded-md py-1 px-2 shadow-2xl text-sm'
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            Rango de fecha
          </button>
          {dateRange?.startDate && (
            <div className='text-gray-500 flex flex-col justify-center'>
              {`${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`}
            </div>
          )}
          <button
            disabled={Object.keys(dateRange).length == 0}
            className='bg-red-500 disabled:bg-red-200 rounded-md py-2 px-2 shadow-2xl'
            onClick={() => setDateRange({})}
          >
            x
          </button>
        </div>
        <div>
          <p className='text-base flex justify-end mt-1 mx-5'>Total: {length}</p>
        </div>
      </div>
      <DateRangePicker
        open={openDatePicker}
        toggle={() => setOpenDatePicker(!openDatePicker)}
        onChange={range => {
          setDateRange(range)
          setOpenDatePicker(!openDatePicker)
        }}
      />
      {loading || loadingCompanions ? (
        <div className='h-full w-full flex items-center justify-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className='flex-row justify-between w-full hidden sm:flex h-[75vh]'>
            <div className='flex flex-col w-1/2 text-center grow overflow-auto'>
              <h2 className='text-center text-2xl'>Lobbies</h2>
              <LobbiesList
                data={data}
                setSelectedLobby={setSelectedLobby}
                fields={fields}
                setLength={setLength}
              />
            </div>
            {/* <div className='flex flex-col w-1/2 text-center grow overflow-auto'>
              <h2 className='text-center text-2xl'>Acompañantes</h2>
              <CompanionsList
                fields={fields}
                data={dataCompanions}
                setSelectedCompanion={setSelectedCompanion}
                setLength={setLength}
              />
            </div> */}
          </div>
          <div className='flex flex-col sm:hidden h-[52vh] overflow-auto narrow:h-[68vh]'>
            <div className='flex flex-row w-full justify-center items-center gap-2 mb-3'>
              <p
                className={`text-lg p-2 rounded-lg w-1/2 flex items-center justify-center ${selectedSection === 'lobbies' ? 'bg-primary text-white' : ''
                  }`}
                onClick={() => setSelectedSection('lobbies')}
              >
                Lobbies
              </p>
              {/* <p
                className={`text-lg p-2 rounded-lg w-1/2 flex items-center justify-center ${selectedSection === 'companions' ? 'bg-primary text-white' : ''
                  }`}
                onClick={() => setSelectedSection('companions')}
              >
                Acompañantes
              </p> */}
            </div>
            {/* {selectedSection === 'lobbies' ? ( */}
            <LobbiesList
              data={data}
              setSelectedLobby={setSelectedLobby}
              fields={fields}
              setLength={setLength}
            />
            {/* ) : ( */}
            {/* <CompanionsList
                fields={fields}
                data={dataCompanions}
                setSelectedCompanion={setSelectedCompanion}
                setLength={setLength}
              />
            )} */}
          </div>
        </>
      )}
      {Object.keys(selectedLobby).length != 0 ? (
        <PreLobbyInfo lobbyId={selectedLobby._id} setSelectedLobby={setSelectedLobby} />
      ) : (
        <></>
      )}
      {Object.keys(selectedCompanion).length != 0 ? (
        <CompanionInfo companion={selectedCompanion} setSelected={setSelectedCompanion} />
      ) : (
        <></>
      )}
    </div>
  )
}

const PreLobbyInfo = ({ lobbyId, setSelectedLobby }) => {
  const { loading, data } = useFetch(`${urlApi}/lobbies/id/${lobbyId}`)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!loading) setVisible(false)
  }, [loading])

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={() => setVisible(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)'
          },
          content: {
            width: '50%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#f8f8f8',
            fontSize: '1.5rem'
          }
        }}
      >
        Cargando...
      </Modal>
      {!loading && <LobbyInfo lobby={data} setSelected={setSelectedLobby} />}
    </>
  )
}
