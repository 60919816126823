import useFetch from '../../hooks/useFetch'
import { urlApi } from '../../config'

const Transactions = ({ lobbyId }) => {
  const { loading, data } = useFetch(`${urlApi}/admin/lobbies/transactions/${lobbyId}`)

  return (
    <div>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div>
          {!data || data.length == 0 ? (
            <div className=' font-extrabold text-2xl'>No hay transacciones</div>
          ) : (
            data.map((transaction, index) => (
              <div key={index} className='bg-gray-300 rounded-xl p-4 px-7'>
                <div className='flex items-center justify-between'>
                  <div className='flex flex-col items-start'>
                    <div className=''>Id: {transaction._id}</div>
                    <div className=''>Usuario: {transaction.user_id}</div>
                    <div className=''>Fecha: {new Date(transaction.updated).toLocaleString()}</div>
                    <div className='text-blue-500'>
                      https://www.mercadopago.com.uy/checkout/v1/redirect?preference-id=
                      {transaction.preference_id}
                    </div>
                    <div className='flex gap-1'>
                      <div>Estado: </div>{' '}
                      {transaction.status == 'approved' ? (
                        <div className='text-green-500'>Aprobado</div>
                      ) : transaction.status == 'refunded' ? (
                        <div className='text-orange-400'>Reembolsado</div>
                      ) : transaction.status == 'pending' ? (
                        <div className='text-blue-400'>Pendiente</div>
                      ) : (
                        <div className='text-yellow-500'>{transaction.status}</div>
                      )}
                    </div>
                  </div>
                  <div className='flex flex-col items-end'>
                    <div className=''>Asientos: {transaction.preference.metadata.seats}</div>
                    <div className='font-bold'>
                      Reserva: {transaction.preference.metadata.price}
                    </div>
                    <div className='font-bold'>Fee: {transaction.preference.metadata.fee}</div>
                    {transaction.preference.metadata.discount && (
                      <div className='font-bold'>
                        Descuento: {transaction.preference.metadata.discount}
                      </div>
                    )}
                    {transaction?.payment && (
                      <div className=''>Metodo: {transaction.payment.payment_method_id}</div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default Transactions
